.navbar {
    /* position: absolute; */
    width: 100%;
    z-index: 1024;
    top: 0;
    padding: 0;
}

.active-nav .navbar {
    position: absolute;
    width: 100%;
    z-index: 1024;
    top: 0;
    padding: 0;
}

.nav-barnd img {
    max-width: 120px;
    object-fit: cover;
}

.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .6rem;
    width: 100%;
}

.nav-links {
    display: flex;
    align-items: center;
}

.nav-link {
    color: #F2F3F3;
    font-size: 16px;
    font-weight: bold;
    padding: 0 2rem;
    transition: all .3s;
}

.nav-link:hover {
    color: #C5DC6C;
    transition: all .3s;
}

.nav-link.active {
    color: #C5DC6C;
    font-size: 16px;
    font-weight: bold;
    padding: 0 2rem;
    transition: all .3s;
}

.nav-button a {
    margin-left: 2rem;
    width: 30px;
    height: 30px;
    background-color: #C5DC6C;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #000;
}

.cart-button {
    margin-left: 2rem;
    width: 30px;
    height: 30px;
    background-color: #C5DC6C;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #000;
    border: none;
}

.bars {
    display: none;
}

.bars button {
    border: none;
    background-color: transparent;
}

.bars i {
    color: #F5FEE6;
    font-size: 26px;
}


.dropdown-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 250px;
    z-index: 2;
    background-color: #0D0D0D;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
}

.dropdown-wrapper li {
    margin-bottom: 2rem;
}



@media (width <= 768px) { 
    .nav-barnd img {
        max-width: 80px;
        height: auto;
    }
    .bars {
        display: block;
    }
    .nav-links {
        display: none;
    }
}