.touch {
    background-color: #C5FA6F;
    border: 1px solid #191919;
    background-image: url("../Images/Mask\ Group.png");
    background-size: cover;
    background-position: top;
    border-radius: 12px;
    padding: 3rem 0;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}

.touch h1 {
    color: #0D0D0D;
    font-size: 38px;
    font-weight: bold;
    margin-bottom: 1rem;
}

.touch p {
    color: #101901;
    font-size: 16px;
    margin-bottom: 1.2rem;
}

.touch-button {
    color: #C5FA6F;
    font-size: 16px;
    font-weight: bold;
    background-color: #0D0D0D;
    border-radius: 8px;
    padding: 18px 40px;
}

.hero {
    background-image: url("../Images/Group\ 1261152707.png");
    background-size: 100% 100%;
}

.hero-card {
    display: flex;
    flex-flow: column;  
    justify-content: center;
    align-items: center;
    height: 95vh;
    width: 50%;
    text-align: center;
}

.hero h1 {
    font-weight: bold;
    font-size: 48px;
    color: #F5FEE6;
    margin-bottom: 2rem;
}

.hero h1 img {
    width: 120px;
}

.hero h2 {
    background: linear-gradient(90deg, #C5DC6C 0%, #FFFFFF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 1rem;
}

.hero p {
    color: #F5FEE6;
    font-size: 16px;
}

.submit {
    background: linear-gradient(90deg, #C5DC6C 0%, #759442 100%);
    width: auto !important;
    padding: .6rem 2rem !important;
    display: unset !important;
    color: #000 !important;
    border-radius: 8px !important;
    font-size: 18px !important;
    font-weight: bold !important;
    border: none !important;
    transition: all .3s !important;
}

.submit i {
    margin-left: 0.6rem;
}

.hero-img {
    display: none;
}

.package {
    padding-bottom: 5rem;
}

.package-eSim {
    background-image: url("../Images/Group\ 1261152692.png");
    height: auto;
    padding: 2rem;
    margin-bottom: 1rem;
    background-size: 100% 100%;
    background-position: center right;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
}

.package h2 {
    color: #101901;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.package h3 span {
    color: #101901;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.package h3 {
    color: #101901;
    font-size: 22px;
    font-weight: normal;
    display: flex;
    align-items: center;
}

.package ul {
    width: 100%;
}

.package ul li {
    background: #C5FA6F33;
    border-radius: 4px;
    color: #F5FEE6;
    padding: .4rem 1rem;
    display: flex;
    align-items: center;
    margin-bottom: .5rem;
    width: 100%;
}

.package ul li h4 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin: 0;
    border-radius: 4px;
    color: #F5FEE6;
    font-size: 16px;
}

.package ul li i {
    background-color: #CCD2E3;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 8px;
    color: #101901;
    margin-right: .5rem;
}

.work {
    padding: 5rem 0;
}

.main-heading {
    color: #FFF;
    font-weight: bold;
    font-size: 36px;
    margin-bottom: 0.5rem;
}

.main-description {
    color: #F5FEE6;
    font-size: 16px;
    margin-bottom: 1.5rem;
}

.step {
    text-align: center;
    position: relative;
}

.work .col-md-4 {
    position: relative;
}

.work .col-md-4::after {
    background-image: url("../Images/Group\ 15.png");
    position: absolute;
    top: 2.5rem;
    right: -2rem;
    content: "";
    width: 82px;
    height: 35px;
    background-repeat: no-repeat;
    background-size: cover;
}

.work .col-md-4:nth-child(4)::after {
    background-image: none;
}

.step-1-img {
    width: 97px;
    height: 90px;
    margin-bottom: 1rem;
}

.step h3 {
    color: #FFFFFF;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 0.3rem;
}

.step p {
    color: #F5FEE6;
    font-size: 16px;
    margin-bottom: 2rem;
    /* max-height: 50px; */
}

.step .step-img {
    height: auto;
    object-fit: cover;
    width: 100%;
}

.explore {
    padding: 5rem 0;
}

.country-card img {
    width: 100%;
    height: 280px;
    transition: all .3s;
    border-radius: 8px;
}

.country-card a {
    color: #FFFFFF;
}

.country-card {
    position: relative;
    border-radius: 8px;
    transition: all .3s;
    margin-bottom: 1rem;
    overflow: hidden;
}

.country-card:hover .country-layout {
    top: -100%;
    transition: all .3s;
}

.country-card:hover img {
    transform: scale(1.1);
    transition: all .3s;
}

.country-layout {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    text-align: center;
    padding-top: 25%;
    background-color: #0000004D;
    transition: all .3s;
}

.choose {
    padding: 5rem 0;
}

.choose img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.choose ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    height: 100%;
}

.choose ul li {
    display: flex;
    margin-bottom: 1rem;
}

.choose ul li img {
    width: 35px;
    height: 35px;
    margin-right: 1rem;
}

.choose ul li h3 {
    font-size: 28px;
    font-weight: bold;
    color: #FFFFFF;
}

.choose ul li p {
    font-size: 16px;
    color: #F5FEE6;
}

.choose .plant-img {
    max-width: 410px;
    height: auto;
    object-fit: cover;
    margin-bottom: 1.5rem;
}

.faqs {
    padding: 5rem 0;
}

.faqs .faq {
    margin-bottom: 1.3rem;
    padding: 1.2rem;
    background: #FFFFFF0D;
    border-radius: 8px;
    box-shadow: 0px 5px 16px 0px #080F340F;
}

.faqs .faq .faq-question {
    position: relative;
    font-size: 22px;
    font-weight: bold;
    padding-right: 80px;
    color: #C5DC6C;
    transition: all 0.4s ease;
}

.faqs .faq .faq-question::after {
    content: "+";
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    background-color: #111111;
    border-radius: 8px;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    transition: all 0.4s ease;
}

.faqs .faq .faq-answer {
    opacity: 0;
    max-height: 0;
    color: #F5FEE6;
    font-size: 16px;
    overflow: hidden;
    transition: all 0.4s ease;
}

.faqs .faq.open .faq-question {
    margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
    content: "-";
    background-color: #C5DC6C;
    color: #111;
}

.faqs .faq.open .faq-answer {
    max-height: 1000px;
    opacity: 1;
}

.eSIMs {
    padding: 5rem 0;
}

.eSIMs .react-tabs__tab-list {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
}

.eSIMs .react-tabs__tab-list .react-tabs__tab {
    /* background: #FFFFFF0D; */
    /* border: 1px solid;
    border-image-source: linear-gradient(152.14deg, rgba(197, 250, 111, 0.7) 9.96%, rgba(245, 254, 230, 0.7) 100%); */
    background-image: url("../Images/Subtract\ \(2\).png");
    background-size: 100% 100%;
    margin: 0 2rem;
    padding: 1rem 2.4rem;
    color: #C5DC6C;
    outline: none;
    border: none;
    cursor: pointer;
    width: auto;
    box-shadow: none;
    font-size: 16px;
    /* font-weight: normal; */
}

.eSIMs .react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected {
    background-image: url("../Images/Subtract\ \(3\).png");
    color: #000;
}

.eSIMs .country-package {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    margin-bottom: 1rem;
    border: 1px solid #C5DC6C;
    background: #FFFFFF0D;
    border-radius: 8px;
}

.eSIMs .country-package .country-flag {
    width: 30px;
    height: 20px;
    object-fit: cover;
    margin-right: 0.5rem;
}

.eSIMs .country-package h3 {
    color: #F2F3F3;
    font-size: 19px;
    font-weight: bold;
    margin-bottom: 0;
}

.eSIMs .country-package i {
    font-size: 18px;
    color: #F2F3F3;
}

.eSIMs form {
    width: 60%;
    margin: auto;
    margin-bottom: 3rem;
}

.eSIMs .form-group {
    border: 1.53px solid #1212122B;
    background: #F1F1F1;
    padding: .2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row;
    border-radius: 8px;
    width: 100%;
} 

.eSIMs .form-group input {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    color: #000;
    background-image: url("../Images/search-normal.png");
    background-position: center left;
    background-repeat: no-repeat;
    background-size: 3% 80%;
    padding-left: 2rem;
    margin-top: 0.2rem;
    width: 100%;
}

.eSIMs .form-group i {
    background: #101901;
    border-radius: 8px;
    width: 60px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #C5DC6C;
    font-size: 18px;
}

.global-package {
    padding: 1rem;
    margin-bottom: 1rem;
    border: 1px solid #C5DC6C;
    background: #FFFFFF0D;
    border-radius: 8px;
    text-align: center;
}

.global-package h2 {
    color: #C5DC6C;
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.global-package h2 span {
    color: #F5FEE6;
    font-size: 16px;
    font-weight: normal;
}

.global-package p {
    color: #F5FEE6;
    font-size: 16px;
    margin-bottom: 1.5rem;
    border-bottom: 1.5px solid #F3F3F3;
    padding-bottom: 1.5rem;
}

.global-package .number-of-countries {
    color: #FFFFFF;
    font-size: 14px;
    padding: .3rem .5rem;
    border: 0.5px solid #F5FEE6;
    padding-top: 0.6rem;
}

.global-package .data {
    border-bottom: 1px solid #F3F3F333;
    padding-bottom: 0.6rem;
    margin-bottom: 0.6rem;
}

.global-package .data h3 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    color: #F2F3F3;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0;
    padding-top: 0.4rem;
    padding-bottom: 0.2rem;
}

.global-package .data h3 img {
    width: 24px;
    height: 24px;
    margin-right: 0.8rem;
}

.utility {
    background-image: url("../Images/803f8f8ae86511ade1c7c6a1dcff385f.png");
    height: 40vh;
    background-size: 100% 100%, contain;
    position: relative;
}

.utility-card {
    background-image: url("../Images/3aecc907f995d84427b3721050e3a086.png");
    background-color: #000000c2;
    padding: 1rem;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.utility h1 {
    color: #F5FEE6;
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 0rem;
}

.utility p {
    color: #F5FEE6;
    font-size: 16px;
    width: 50%;
    margin: 0 auto;
}

.about {
    border-bottom: 1px solid #262626;
    padding: 5rem 0;
}

.about h2 {
    color: #FFFFFF;
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 1rem;
}

.about p {
    color: #F5FEE6;
    font-size: 16px;
    margin-bottom: 1rem;
}

.mission {
    padding: 3rem 0;
}

.vision {
    background: #FFFFFF0D;
    border: 0.5px solid #C5FA6F;
    padding: 2rem;
    border-radius: 8px;
    margin-bottom: 1rem;
    text-align: center;
}

.vision img {
    margin-bottom: 2rem;
    object-fit: cover;
}

.vision h2 {
    color: #FFFFFF;
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 1rem;
}

.vision {
    color: #F5FEE6;
    font-size: 16px;
    margin-bottom: 1rem;
}

.video-player {
    margin-top: 3rem;
    width: 100%;
    height: 450px;
    border-radius: 8px;
    object-fit: cover;
}

.video-player .video-module_videoPosterPlayButton__CJm-m {
    background-color: transparent;
}

.video-player .video-module_videoPosterPlayButton__CJm-m svg path {
    fill: #FFF;
}

.contact {
    padding: 5rem 0;
}

.contact-form {
    background: #FFFFFF0A;
    border: 2px solid #0A0D170D;
    border-radius: 20px;
    padding: 2rem;
}

.contact-form h2 {
    color: #F5FEE6;
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 0;
}

.contact-form p {
    color: #F5FEE6;
    font-size: 16px;
    margin-bottom: 1rem;
}

.contact-form .form-group {
    display: flex;
    flex-flow: column;
    margin-bottom: 1rem;
}

.contact-form .form-control {
    color: #949D9E;
    background: #0D0D0D;
    border: 1px solid #19212C;
    padding: .6rem 1rem;
    box-shadow: none;
    outline: none;
    border-radius: 8px;
}

.contact-form .form-control::placeholder {
    color: #949D9E;
    font-size: 16px;
}

.contact-form .form-group label {
    color: #F5FEE6;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0.3rem;
}

.contact-form img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
}

.contact-form .submit {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 0.8rem;
}

.contact-img {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-details {
    padding: 5rem 0;
}

.details-card {
    border: 2px solid #0A0D170D;
    background: #FFFFFF0A;
    padding: 1rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    border-radius: 6px;
}

.details {
    display: flex;
    align-items: center;
}

.details-card i {
    color: #C5DC6C;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 2px solid #C5DC6C;
    margin-right: 0.7rem;
    font-size: 22px;
}

.details-card h3 {
    color: #C5DC6C;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0.2rem;
}

.details-card a {
    color: #FFFFFF;
    font-size: 16px;
}

.error-card {
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-flow: column;
}

.error-card h1 {
    color: #C5DC6C;
    font-size: 128px;
    font-weight: bold;
}

.error-card h2 {
    color: #C5DC6C;
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 0rem;
}

.error-card p {
    color: #F5FEE6;
    font-size: 16px;
    margin-bottom: 2rem;
}

.terms {
    padding: 3rem 0;
}

.terms h1 {
    color: #FFFFFF;
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 1rem;
}

.terms h2 {
    color: #F5FEE6;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 1rem;
}

.terms p {
    color: #F5FEE6;
    font-size: 16px;
    margin-bottom: 1rem;
}

.destination {
    padding: 3rem 0;
}

.destination h1 {
    text-align: center;
    margin-bottom: 2rem;
    color: #FFF;
    font-weight: bold;
    font-size: 32px;
}

.destination p {
    margin-bottom: 2rem;
    color: #F5FEE6;
    font-size: 16px;
}

.destination img {
    width: 100%;
    height: auto;
    object-fit: cover;
    margin-bottom: 2rem;
}

.destination-images {
    display: flex;
    flex-flow: row;
    overflow: hidden;
}

.add-icon {
    width:30px;
    height:30px;
    background: #C5DC6C;
    border: 1.5px solid #0D0D0D;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #000;
    font-size: 14px;
}

.add-button {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;
}

.hidden{
    display: none;
}

.img-container {
    border-radius: 50%;
    border: .5px solid black;
    height: 80px;
    width: 80px;
    position: relative;
}
.img-container img{
    height: 80px;
    width: 80px;
    object-fit: cover;
    border-radius: 50%;
    display:block;
}

.account {
    padding: 3rem 0;
}

.myAccount {
    background: #19191980;
    padding: 2rem 1rem;
    border-radius: 8px;
}

.profile-links li a {
    color: #949D9E;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 1rem;
}

.profile-links li {
    margin-bottom: 1rem;
}

.profile-links li.active {
    border-bottom: 1px solid #C5DC6C;
}

.profile-links li.active a {
    color: #C5DC6C;
}

.global-package {
    padding: 0;
}

.global-package h1 {
    background-color: #C5DC6C;
    color: #101901;
    font-size: 33px;
    font-weight: bold;
    padding: .7rem;
}

.counter-button {
    border: .5px solid #F2F3F3;
    padding: .4rem;
    width: 60px;
}

.counter-button button {
    background-color: transparent;
    color: #F5FEE6;
    border: none;
    font-size: 14px;
    padding: 0;
}

.dropdown-button {
    background-color: transparent;
    border: none;
    text-align: start;
    color: #C5DC6C;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: start; 
    margin: 1rem 0;
}

.dropdown-button i {
    margin-right: 0.5rem;
}

.modal-content {
    background-color: #000;
}

.modal {
    /* height: 100vh; */
    /* position: relative; */
    /* background-color: #C5DC6C; */
    /* z-index: 2014; */
}

.myAccount h2 {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 2rem;
}

.myAccount h2 i {
    margin-right: 0.4rem;
}












.login input {
    background-color: transparent !important;
    color: #FFF !important;
    box-shadow: none !important;
    outline: none !important;
}

.form input {
    background-color: transparent !important;
    color: #FFF !important;
    box-shadow: none !important;
    outline: none !important;
}


@media (width <= 768px) { 
    .hero {
        background-image: none;
        height: 100%;
    }
    .hero-card {
        width: 100%;
        height: 100%;
        padding-bottom: 3rem;
    }
    .hero-img {
        display: block;
        width: 100%;
        height: auto;
        object-fit: cover;
    }
    .hero h1 {
        font-size: 22px;
    }
    .hero h1 img {
        width: 60px;
    }
    .hero h2 {
        font-size: 22px;
    }
    .hero p {
        font-size: 14px;
    }
    .work .col-md-4::after {
        background-image: none;
    }
    .step-1-img {
        /* display: none; */
        margin: 1rem 0;
    }
    .step h3 {
        margin: 1rem 0;
    }
    .step p {
        display: none;
    }
    .touch {
        padding: 1.5rem .5rem;
        text-align: center;
    }
    .eSIMs .react-tabs__tab-list .react-tabs__tab {
        margin: 0.3rem;
        padding: .6rem;
    }
    .utility p {
        width: 100%;
    }
    .contact-img {
        margin-top: 2rem;
    }
    .destination-images {
        flex-flow: column;
    }
    .eSIMs .form-group input {
        background-size: 8% 80%;
    }
}


.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 2fr;
  grid-template-rows: auto;
  gap: 10px; /* Adjust as needed */
}

.grid-container .item1 {
  grid-column: 1 / 3;
  grid-row: 1 / 3;
}

.grid-container .item2 {
  grid-column: 3 / 5;
  grid-row: 1;
}

.grid-container .item3 {
  grid-column: 3 / 4;
  grid-row: 2;
}

.grid-container .item4 {
  grid-column: 4 / 5;
  grid-row: 2;
}

.react-tel-input .country-list {
    background-color: #000 !important;
}

.react-tel-input .form-control {
    width: 100% !important;
    border: none !important;
}

.react-tel-input .selected-flag {
    background: #000 !important;
    border: none !important;
}

.error-order {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    margin: 3rem 0;
}

.error-order h1 {
    font-size: 42px;
    font-weight: bold;
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.error-order img {
    width: 70%;
    height: auto;
    object-fit: cover;
}

.success-massage {
    color: #C5FA6F;
    font-size: 14px;
    margin-bottom: 1rem;
}
.error-massage {
    color: #ff0000;
    font-size: 14px;
    margin-bottom: 1rem;
}

.verify-code .form-group {
    flex-flow: column;
    background-color: transparent;
}

.verify-code .form-control {
    background-image: none !important;
    border: 1px solid #949D9E !important;
    color: #FFF !important;
}

.QR {
    width: 100%;
}
