.footer {
    background: #FFFFFF0D;
    box-shadow: 0px 5px 16px 0px #080F340F;
    color: #E4E6E7;
    padding: .8rem;
    margin-top: 1rem;
}

.footer-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.footer p {
    font-size: 16px;
    margin: 0;
}

.footer a {
    font-size: 16px;
    color: #E4E6E7;
}

.footer .pay-img {
    display: flex;
}

.footer .pay-img img {
    width: 35px;
    height: 25px;
    margin-right: 0.6rem;
}

@media (width <= 768px) { 
    .footer-card {
        flex-flow: column;
    }
    .footer-card p {
        margin: 1rem 0;
    }
}