@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');

body {
    background-color: #0D0D0D;
    font-family: "Tajawal", sans-serif;
    color: #F5FEE6;
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 7px;
    height: 4px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: #C5FA6F;
    border-radius: 10px;
}

a {
    text-decoration: none;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}