.login {
    width: 50%;
    margin: 3rem auto;
    border: 1px solid #212629;
    box-shadow: 0px 8px 40px 0px #0000001F;
    /* padding: 0 1rem; */
}

.react-tabs__tab-list {
    display: flex;
    align-items: center;
}

.react-tabs__tab-list .react-tabs__tab {
    cursor: pointer;
    transition: all 0.2s ease;
    border-right: 1px solid #212629;
    border-bottom: 1px solid #212629;
    width: 50%;
    text-align: center;
    padding: 1rem 0;
    color: #77878F;
    font-size: 22px;
    font-weight: bold;
    outline: none;
}

.react-tabs__tab.react-tabs__tab--selected {
    border-bottom: 1px solid #C5DC6C;
    color: #C5DC6C;
    transition: all 0.2s ease;
    box-shadow: 0px -3px 0px 0px #C5DC6C inset;
}

.react-tabs__tab-list .react-tabs__tab:last-of-type {
    border-right: none;
}

.form {
    padding: 2rem;
}

.form-group {
    display: flex;
    flex-flow: column;
    margin-bottom: 1rem;
}

.form-group label {
    color: #F5FEE6;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 0.3rem;
}

.form-control {
    border: 1px solid #19212C;
    color: #FFF;
    padding: .6rem 1rem;
    background-color: transparent;
    outline: none;
    box-shadow: none;
    border-radius: 8px;
}

.form-control::placeholder {
    color: #949D9E;
    font-size: 16px;
}

.submit {
    background: linear-gradient(90deg, #C5DC6C 0%, #759442 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem 0;
    color: #000;
    border-radius: 8px;
    font-size: 22px;
    font-weight: bold;
    border: none;
    transition: all .3s;
}

.submit i {
    margin-left: 0.6rem;
}

.form-check-input {
    background-color: transparent;
    border-color: #C5FA6F;
    outline: none;
}

.form-check-input:checked {
    background-color: #C5FA6F;
    border-color: #C5FA6F;
}

.form-check-input:focus {
    box-shadow: none;
}

.Forget {
    color: #C5FA6F;
    font-size: 16px;
}

p {
    margin-bottom: 0;
    margin-top: 1rem;
    font-size: 16px;
}

.or {
    display: flex;
    align-items: center;
    margin-top: 1rem;
}

.or .or-border {
    height: 1px;
    background-color: #212629;
    width: 48%;
}

.or span {
    color: #F5FEE6;
    font-weight: bold;
    font-size: 16px;
    margin: 0 2%;
}

.login-with-google {
    border: 0.5px solid #F5FEE6;
    border-radius: 8px;
    padding: .8rem 1rem;
    display: flex;
    align-items: center;
    background-color: transparent;
    color: #F5FEE6;
    justify-content: center;
    margin: 1rem 2rem;
    width: 80%;
}

.GoogleAuthButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-with-google img {
    width: 20px;
    height: 20px;
}

.login-with-google span {
    width: calc(100% - 20px);
    color: #F5FEE6;
    font-size: 16px;
    text-align: center;
}

.pass-wrapper {
    border: 1px solid #19212C;
    color: #FFF;
    padding: .6rem 1rem;
    background-color: transparent;
    display: flex;
    align-items: center;
    border-radius: 8px;
}

.pass-wrapper .form-control {
    padding: 0;
    border: none;
}

.pass-wrapper i {
    color: #F5FEE6;
    cursor: pointer;
}

.error {
    color: red;
    font-size: 14px;
}

.forget i {
    color: #F5FEE6;
    margin-right: 1rem;
    font-size: 16px;
}

.forget a {
    color: #F5FEE6;
    margin-right: 1rem;
    font-size: 16px;
}

.forget h1 {
    margin-top: 2rem;
    color: #C5DC6C;
    font-weight: bold;
    margin-bottom: 1rem;
    font-size: 40px;
}

.forget p {
    color: #F5FEE6;
    font-size: 16px;
}


.base {
  margin: 2rem 0;
  text-align: center;
}
.base h1 {
  font-size: 1.2rem;
}
.base .otp-base {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 0.5rem;
}
.base .otp-base input {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid #A4A1AA33;
  outline: none;
  font-size: 2rem;
  background-color: transparent;
  transition: all 0.2s ease;
  color: #F5FEE6;
}
.base .otp-base input:focus {
  border: 1px solid #F5FEE6
}
.base .button {
  visibility: hidden;
  position: absolute;
  margin-top: 2rem;
  right: 0;
  text-transform: uppercase;
  padding: 0.4rem;
  background: none;
  border: 1px solid #574b90;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease;
}
.base .button.show {
  visibility: visible;
}
.base .button:hover {
  background: #574b90;
  color: #fff;
}
.base .otp-error {
  animation: shake 0.3s ease forwards;
  pointer-events: none;
}
.base .otp-error input {
  border-bottom: 2px solid red;
  pointer-events: none;
}
.base .otp-error input:focus {
  border-bottom: 2px solid red;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}


@media (width <= 768px) { 
    .login {
        width: 90%;
    }
}